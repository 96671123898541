import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import { Container } from "../../../../components/ui/Container/Container";
import { Button } from "../../../../components/ui/Buttons/Button/Button";
import "./Feedback.scss";
import { FeedbackTitle } from "../../../../components/common/Feedback/FeedbackTitle";
import { useTranslation } from "../../../../hooks";
import { classNames } from "../../../../utils/helpers/classNames";
import { useIsThemeDark } from "../../../../hooks/useIsThemeDark";
import { Slider } from "./components/Slider/Slider";

export const Feedback = () => {
  // **Props
  const { reviews: { allReviews } } = useTranslation();
  const { lang } = useParams();
  const [searchParams] = useSearchParams();
  const s = searchParams.get("s");

  const isThemeDark = useIsThemeDark();

  // **Redux state
  const { content } = useSelector((state) => state.content);

  // *Ref
  const refFeedbacks = useRef(null);

  useEffect(() => {
    if (s === "feedbacks" && refFeedbacks?.current?.clientHeight) {
      refFeedbacks.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [s]);

  return (
    (content?.isLoaded && content?.data[lang]?.siteReviews) && (
      <section
        ref={refFeedbacks}
        className={classNames("feedbacks", { "feedbacks--dark": isThemeDark })}
        id="feedbacks"
      >
        <Container>
          <div className="feedbacks-box">
            <div className="feedbacks__header">
              <FeedbackTitle />
              <div className="feedbacks__header__actions">
                <div className="media__min-width-993">
                  <Link to={`/${lang}/feedback`} className="feedbacks__header__to-all">
                    <Button tab="dark-gradient">{allReviews}</Button>
                  </Link>
                </div>
                <div className="feedbacks__header__btns">
                  <button aria-label="prev slide" className="feedbacks__header__btn-prev">
                    <IconSvg tag="classicArrowLeft" />
                  </button>
                  <button aria-label="next slide" className="feedbacks__header__btn-next">
                    <IconSvg tag="classicArrowLeft" />
                  </button>
                </div>
              </div>
            </div>
            {/* <Button onClick={() => console.log("check")}>
              {"ALL feedbacks"}
            </Button> */}
          </div>
          {/* <FeedbackSources slides={content?.data[lang]?.siteReviews} /> */}
        </Container>
        <Slider slides={content?.data[lang]?.siteReviews} />
        <Container>
          <div className="media__max-width-992">
            <Link to={`/${lang}/feedback`} className="feedbacks__header__to-all">
              <Button tab="dark-gradient">{allReviews}</Button>
            </Link>
          </div>
        </Container>
      </section>
    )
  );
};
