/* eslint-disable camelcase */
import { useEffect, useRef, useState } from "react";

import { Address4 } from "ip-address";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// eslint-disable-next-line import/no-named-as-default
import { ApiService } from "../../../../../services/ApiService";
import { protocols } from "../../../../../utils/constants/protocols.constant";
import { getG4Data } from "../../../../../utils/helpers";
import { checkIpAgainstRanges } from "../../../../../utils/validation/ipValidation";
import { getCountries } from "../../../../../utils/helpers/getCountries.helper";

import { ModalInput } from "../../../../ui/ModalInput/ModalInput";
import { ModalSelect } from "../../../../ui/ModalSelect/ModalSelect";

import { useCurrentRentPeriod } from "../../../../../hooks/useCurrentRentPeriod";
import { useDebounceValue } from "../../../../../hooks/useDebounceValue";
import { useDispatchedActions } from "../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../hooks/useTranslation";

import "./OrderForm.scss";
import { isValidQuantity } from "../../../../../utils/helpers/isValidQuantity";
import { getQuantityWarningMessage } from "../../../../../utils/helpers/getQuantityWarningMessage";
import { Button } from "../../../../../components/ui/Buttons";
import {
  errorToast,
  warningToast
} from "../../../../../utils/helpers/customToast.helper";
import { AgreeWithRules } from "../../../agreeWithRules/AgreeWithRules";
import { CfCaptcha } from "../../../CfCaptcha/CfCaptcha";
import { ButtonLoader } from "../../../../ui/ButtonLoader/ButtonLoader";
import { AuthParams } from "./components/AuthParams";
import { ClientEmail } from "./components/ClientEmail";
import { PaymentSystem } from "./components/PaymentSystem";
import { MobileoperatorParams } from "./components/MobileoperatorParams";

export const Form = () => {
  // **Props
  const { lang } = useParams();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const reCaptchaRef = useRef();

  // **Redux State
  const { modal } = useSelector((state) => state);

  const { projects_form_crm } = useSelector(
    (state) => state.content.proxies.data
  );
  const {
    // content,
    // isCaptchaEnabled,
    countries,
    activeData,
    user,
    proxies: { fiveDollarLogick }
  } = useSelector((state) => state.content);

  // **Dispatch
  const {
    // setModalOpen,
    // clearPrices,
    setRentPeriod,
    setCountryModal,
    fetchPriceCalculationModal,
    setParamsForReload,
    resetParamsForReload
    // ,setUseFiveDollarLogick,
    // setModalNotAuthBalance,
    // setFooterModal,
    // setModalSelectBalance,
    // setIsBalanceCheck
  } = useDispatchedActions();

  // **State
  const [options, setOptions] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  // const [displayPrices, setDisplayPrices] = useState("-");
  const [proxyProtocol, setProxyProtocol] = useState(null);
  const [isAgreeWithConditions, setIsAgreeWithConditions] = useState(false);

  const [cfToken, setCfToken] = useState("");
  const cfCaptchaRef = useRef(null);

  const [modemRotationMin, setModemRotationMin] = useState({});
  const [mobileOperator, setMobileOperator] = useState({});
  const [europeCountries, setEuropeCountries] = useState([]);
  const [quantity, setQuantity] = useState("");
  // const [useReCaptchaV3, setUseReCaptchaV3] = useState(true);
  // const [tokenV2, setTokenV2] = useState(null);
  const [userBalance, setUserBalance] = useState({
    balance: 0,
    currencyCode: "",
    success: false
  });

  // Form
  const methods = useForm({
    mode: "onSubmit"
  });

  const { rent, asyncGoals, payment, authIp } = methods.watch();
  // **Hooks
  const {
    formPlaceholders,
    orderForm,
    notifications,
    prices,
    main: {
      agreeWithRules: { warningMessage }
    }
  } = useTranslation();
  const debouncedQuantity = useDebounceValue(quantity, 500);
  const rentPeriods = useCurrentRentPeriod(modal?.proxyType);

  // Ref
  const goalsSelect = useRef(null);

  const analitics = () => {
    let orderLabel = "";
    switch (modal?.proxyType) {
      case "IPv4":
        orderLabel = "order";
        break;
      case "IPv6":
        orderLabel = "order_ipv6";
        break;
      case "MOBILE":
        orderLabel = "order_mobile";
        break;
      default:
        orderLabel = "order";
        break;
    }
    // google analitika
    if (
      window.location.hostname !== "localhost" &&
      typeof window.gtag !== "undefined"
    ) {
      // eslint-disable-next-line no-undef
      window.gtag("event", "Click", {
        // eslint-disable-next-line camelcase
        event_category: "Pay_Button",
        // eslint-disable-next-line camelcase
        event_label: orderLabel
      });
    }

    // yandex analitika
    if (
      window.location.hostname !== "localhost" &&
      typeof window.ym !== "undefined"
    ) {
      // eslint-disable-next-line no-undef
      ym(87392270, "reachGoal", orderLabel);
    }
  };

  const combineParams = (data, token) => {
    let projectId = "";
    let ownSubgoal = "";
    // logik use iv4/crm goals
    if (projects_form_crm) {
      ownSubgoal = data?.asyncGoals?.value.trim();
    } else {
      projectId =
        data?.asyncGoals?.label.trim() !== data?.asyncGoals?.value.trim()
          ? data?.asyncGoals?.value.trim()
          : "";
      ownSubgoal =
        data?.asyncGoals?.label.trim() === data?.asyncGoals?.value.trim()
          ? data?.asyncGoals?.value.trim()
          : "";
      const equalValue = options?.filter(
        ({ label }) =>
          label.toLowerCase() === data?.asyncGoals?.value.toLowerCase().trim()
      );

      if (equalValue.length > 0) {
        projectId = equalValue[0]?.value;
        ownSubgoal = "";
      }
    }

    if (
      data?.auth?.value === "ip" &&
      data?.authIp &&
      !Address4.isValid(data?.authIp)
    ) {
      throw notifications?.wrongIp;
    }
    return {
      paymentSystemId: data?.payment?.id,
      currency: modal?.prices?.currencyCode,
      countryId: modal?.country?.id,
      count: +data?.quantity,
      days: data?.rent?.days,
      projectId,
      authType: data?.auth?.value,
      locale: lang,
      projectCustom: ownSubgoal || "",
      price: modal?.prices?.price,
      captchaToken: token ? token : "",
      amount: modal?.prices?.amount || 0,
      clientEmail: data.mail || "",
      authIp: data?.auth?.value === "ip" && data?.authIp ? data.authIp : "",
      proxyType: modal?.proxyType,
      proxyProtocol,
      modemRotationMin: modemRotationMin?.min,
      mobileOperatorTag: mobileOperator?.value,
      // captchaV2
      ga4Info: getG4Data()
    };
  };

  const hendlerSetFormParam = (name, data) => methods.setValue(name, data);

  const createOrder = async () => {
    const data = methods.getValues();
    let token = cfToken;

    if (cfCaptchaRef?.current && !token) {
      token = await cfCaptchaRef.current?.getResponsePromise();
    }

    try {
      const params = combineParams(data, token, true);
      const response = await ApiService.createPayment(params);
      if (response && (response?.status < 200 || response?.status >= 300)) {
        if (response?.data === "incorrect ip") {
          throw notifications?.wrongIp;
        }
        throw notifications.error;
      }

      if (response && response?.status === 202) {
        throw notifications?.errorCaptcha;
      }
      if (response && response?.status === 205) {
        setParamsForReload(data);
        throw notifications?.errorUserPaymentSystem;
      }
      if (response && response?.status === 206) {
        throw notifications?.tempMail;
      }
      if (response && response?.status === 226) {
        throw notifications.registerErrorWrongEmail;
      }

      if (response && response?.status === 200) {
        if (response?.data && response?.status === 200) {
          analitics();
          cfCaptchaRef?.current?.reset();
          window.location.href = response.data;
        } else {
          throw notifications.error;
        }
      } else {
        throw notifications.error;
      }
    } catch (err) {
      errorToast(err?.message || err, lang);
      setIsFormSubmitting(false);
      if (reCaptchaRef.current) {
        reCaptchaRef.current?.reset();
      }
    }
  };

  // Form submit
  const onSubmit = (data) => {
    if (!isAgreeWithConditions) {
      return warningToast(warningMessage, lang);
    }

    if (data?.auth?.value === "ip" && data?.authIp && checkIpAgainstRanges(data?.authIp)) {
      return warningToast(notifications?.invalidIp, lang);
    }

    if (
      fiveDollarLogick?.enabled &&
      modal?.prices?.usdAmount < 5 &&
      data?.payment?.code !== "balance"
    ) {
      if (
        user?.isAuth &&
        userBalance?.balance &&
        userBalance?.balance >= modal?.prices?.amount
      ) {
        // setModalSelectBalance(true);
        // setParamsForReload({ data, balance: userBalance?.balance });
        // setIsBalanceCheck(true);
        // setUseFiveDollarLogick(true);
        // setModalOpen(true);
        warningToast(
          `${prices.lowAmount} ${prices.infoModalSelectBalance}`,
          lang
        );
        return null;
      } else {
        // setUseFiveDollarLogick(true);
        // setModalOpen(true);
        warningToast(prices.lowAmount, lang);
        return null;
      }
    } else if (
      fiveDollarLogick?.enabled &&
      data?.payment?.code === "balance" &&
      !user?.isAuth
    ) {
      // setModalNotAuthBalance();
      // setUseFiveDollarLogick(true);
      warningToast(prices.notAuthBalance, lang);
      return null;
    } else if (
      data?.payment?.code === "balance" &&
      user?.isAuth &&
      modal?.prices?.amount &&
      userBalance?.balance < modal?.prices?.amount
    ) {
      // setParamsForReload({ data, balance: userBalance?.balance });
      // setIsBalanceCheck(true);
      warningToast(prices.lowBalance.main);
      return null;
    } else if (
      modal?.prices?.minPrice &&
      +modal?.prices?.amount < +modal?.prices?.minPrice &&
      modal?.isPricesLoaded
    ) {
      warningToast(
        `${notifications?.minPrice} ${modal?.prices?.minPrice} ${modal?.prices?.currencyCode}`,
        lang
      );
    } else if (modal?.isPricesLoaded) {
      setIsFormSubmitting(true);
      createOrder();
      // if (
      //   isCaptchaEnabled &&
      //   reCaptchaRef.current &&
      //   content?.data[lang]?.google_captcha_v2_public_key
      // ) {
      //   setIsFormSubmitting(true);
      //   reCaptchaRef.current.execute();
      // } else {
      //   setIsFormSubmitting(true);
      //   createOrder();
      // }
    }
  };

  // const onChange = (value) => {
  //   setTokenV2(value);
  // };

  // Set initial value for rent period
  useEffect(() => {
    if (user?.isAuth) {
      ApiService.getBalance()
        .then((req) => {
          if (req?.data?.success) {
            setUserBalance(req.data);
          }
        })
        .catch();
    }
    if (modal?.paramsForReload?.isSet) {
      const temp = Object.keys(modal?.paramsForReload?.data);
      temp.forEach((item) => {
        if (item !== "payment") {
          methods.setValue(item, modal?.paramsForReload?.data[item]);
        }
      });
      setQuantity(modal?.paramsForReload?.data?.quantity);
      if (modal?.paramsForReload?.data?.mobileOperator) {
        setMobileOperator(modal?.paramsForReload?.data?.mobileOperator);
      }
      resetParamsForReload();
    } else {
      setQuantity(modal?.quantity);
      methods.setValue("rent", modal?.days);
      if (modal?.proxyType === "MOBILE") {
        if (modal?.operatorWithMinPrice && modal?.rotationWithMinPrice) {
          const initialOperator = modal?.mobileOperators.find(
            (operator) => operator.index === modal?.operatorWithMinPrice.index
          );

          const initialRotation = initialOperator?.rotationMins.find(
            (rotation) => rotation.value === modal?.rotationWithMinPrice
          );

          methods.setValue("mobileOperator", initialOperator);
          methods.setValue("modemRotationMin", initialRotation);

          setMobileOperator(initialOperator);
          setModemRotationMin(initialRotation);
        }
      }
      if (modal?.proxyType === "IPv4" && modal?.activeEurope) {
        const sortCountries = getCountries(countries?.data, lang, {
          isEurope: false,
          code: "EUROPE",
          showOnFrontType: modal?.proxyType
        });
        setEuropeCountries(sortCountries);
        methods.setValue(
          "country",
          sortCountries.find((item) => item?.id === modal?.country?.id)
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Calculate price
  useEffect(() => {
    if (
      modal?.country?.id &&
      activeData?.currency?.id &&
      modal?.proxyType &&
      !!debouncedQuantity &&
      rent?.id
    ) {
      const equalValue = options?.filter(
        ({ label }) =>
          label.toLowerCase() === asyncGoals?.value.toLowerCase().trim()
      );
      fetchPriceCalculationModal({
        projectId:
          (equalValue?.length > 0
            ? equalValue[0]?.value
            : asyncGoals?.value.trim()) || null,
        countryId: modal?.country?.id,
        currencyId: activeData?.currency?.id,
        paymentSystemId: modal?.activePaymentSystem?.id || null,
        count: +debouncedQuantity,
        rentPeriodId: rent?.id,
        proxyType: modal?.proxyType,
        mobileOperatorTag: mobileOperator?.value || null,
        rotationMin: modemRotationMin?.min >= 0 ? modemRotationMin?.min : null,
        rotationSelected: Boolean(modemRotationMin?.min >= 0)
      });
    } else {
      // clearPrices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modal?.country?.id,
    modal?.activePaymentSystem?.id,
    debouncedQuantity,
    rent?.id,
    asyncGoals?.value,
    options,
    mobileOperator?.value,
    modemRotationMin
  ]);

  // Handling error
  if (modal?.pricesError) {
    errorToast(notifications.error, lang);
  }
  const checkQuantity = () => {
    if (!isValidQuantity(modal?.proxyType, debouncedQuantity)) {
      getQuantityWarningMessage(modal?.proxyType, lang);
    }
    // if (+debouncedQuantity < 10 && modal?.proxyType === "IPv6") {
    //   errorToast(prices?.minQuantityIPv6, lang);
    // }
  };

  return (
    <div className="order__form order__form--main">
      {/*<div className={isFormSubmitting ? "order__form-hiden" : ""}>*/}
      <div>
        <div className="order__form__top">{orderForm?.title}</div>
        <div className="order__form__description">{orderForm?.description}</div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="order__form"
            noValidate={true}
          >
            {modal?.activeEurope && (
              <ModalSelect
                name="country"
                defaultValue={europeCountries.find(
                  (europeCountry) => europeCountry.id === modal?.country?.id
                )}
                rules={{ required: true }}
                options={europeCountries}
                placeholder={formPlaceholders?.country}
                onChange={(value) => {
                  setCountryModal({
                    id: value?.id,
                    code: value?.code
                  });
                }}
              />
            )}

            {modal?.proxyType === "IPv6" && (
              <ModalSelect
                name="proxyProtocol"
                rules={{ required: true }}
                options={protocols}
                placeholder={formPlaceholders?.protocol}
                onChange={(i) => {
                  setProxyProtocol(i?.value);
                }}
              />
            )}

            <ModalSelect
              ref={goalsSelect}
              name="asyncGoals"
              rules={{
                required: true
              }}
              placeholder={formPlaceholders?.proxyGoalField?.select}
              isAsync
              isSelectValid
              setOptions={setOptions}
            />
            <div className="order__form-row order__form-dynamic-row">
              <ModalInput
                type="text"
                name="quantity"
                proxyType={modal?.proxyType}
                placeholder={formPlaceholders?.quantity2}
                placeholderNoData={formPlaceholders?.quantity1}
                defaultValue={
                  // modal?.quantity || modal?.paramsForReload?.data?.quantity
                  modal?.quantity
                }
                handler={(data) => setQuantity(data)}
                quantity
                rules={{
                  required: true,
                  min: modal?.proxyType === "IPv6" ? 10 : 1
                }}
              />
              <ModalSelect
                name="rent"
                rules={{ required: true }}
                options={rentPeriods}
                placeholder={formPlaceholders?.rentPeriod}
                onChange={(value) => {
                  setRentPeriod(value);
                }}
              />
            </div>
            <MobileoperatorParams
              proxyCode={modal?.proxyType}
              mobileOperator={mobileOperator}
              setMobileOperator={setMobileOperator}
              setModemRotationMin={setModemRotationMin}
            />
            <AuthParams authIp={authIp} />
            <ClientEmail methods={methods} />
            <PaymentSystem data={payment} setData={hendlerSetFormParam} />
            <AgreeWithRules
              defaultValue={isAgreeWithConditions}
              handler={() => setIsAgreeWithConditions((prev) => !prev)}
            />
            {/* <ModalInput
              type="checkbox"
              name="policy"
              defaultValue
              rules={{
                required: true
              }}
            /> */}

            {modal?.prices?.priceMobileRotation &&
              modal?.proxyType === "MOBILE" && (
                <div className="order__form-prices">
                  <div className="order__form-prices--pieces">
                    {orderForm?.rotation} <span>{modemRotationMin?.label}</span>
                  </div>
                  <div className="order__form-prices--full">
                    {modal?.prices?.priceMobileRotation}
                    <span> {modal?.prices?.currencyCode}</span>
                  </div>
                </div>
              )}
            {modal?.prices?.priceMobileOperator &&
              modal?.proxyType === "MOBILE" && (
                <div className="order__form-prices">
                  <div className="order__form-prices--pieces">
                    {orderForm?.operator} <span>{mobileOperator?.label}</span>
                  </div>
                  <div className="order__form-prices--full">
                    {modal?.prices?.priceMobileOperator}
                    <span> {modal?.prices?.currencyCode}</span>
                  </div>
                </div>
              )}
            <div className="order__form-prices">
              <div className="order__form-prices--pieces">
                {/*{displayPrices}*/}
                {modal?.prices?.usdPrice ? `$${modal?.prices?.usdPrice}` : "$0"}
                <span>
                  {/*{modal?.prices?.currencyCode} / {formPlaceholders?.quantity2}*/}
                  {/*USD / {formPlaceholders?.quantity2}*/}
                  / IP
                </span>
              </div>
              <div className="order__form-prices--full">
                <span className="order__form-prices--full-name">
                  {" "}
                  {orderForm?.fullPrice}:
                </span>
                <span className="order__form-prices--full-number">
                  {modal?.prices?.usdAmount ? `$${modal?.prices?.usdAmount}` : "$0"}
                </span>
                {/*{modal?.prices?.currencyCode}*/}
              </div>
            </div>
            <CfCaptcha ref={cfCaptchaRef} setCfToken={setCfToken} />
            <Button
              className="order__form-btn"
              type="submit"
              tab="buy-proxy"
              onClick={checkQuantity}
              // disabled={!useReCaptchaV3 && !tokenV2}
              disabled={isFormSubmitting}
            >
              {isFormSubmitting ? <ButtonLoader /> : orderForm?.payBtn}
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
