import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export const TrustBox = () => {
  // const { lang } = useParams();
  const { skin } = useSelector((state) => state.header);

  const ref = useRef(null);

  useEffect(() => {
    if (window?.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [skin]);

  // const getLocale = () => {
  //   const locales = {
  //     ru: "ru-RU",
  //     en: "en-US"
  //   };
  //
  //   return locales[lang] || "en-US";
  // };

  return (
    <div
      className="trustbox-wrapper--en"
      style={{ textAlign: "center", maxWidth: "max-content", position: "relative", left: "16px" }}
    >
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5419b732fbfb950b10de65e5"
        data-businessunit-id="61e42ccf734f96a7bb7efa14"
        data-style-height="20px"
        data-style-width="100%"
        // data-style-text-align="left"
        data-theme={skin || "dark"}
      >
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://www.trustpilot.com/review/proxy-ipv4.com" target="_blank" rel="noopener">Trustpilot</a>
      </div>
    </div>
  );
};
