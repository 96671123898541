import { useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PriceItem } from "../PriceItem/PriceItem";
import { Container } from "../../../../../../components/ui/Container/Container";
import { EnterpriseItem } from "../EnterpriseItem/EnterpriseItem";
import { errorToast } from "../../../../../../utils/helpers/customToast.helper";
import { ApiService } from "../../../../../../services/ApiService";
import { PriceItemSkeleton } from "../PriceItem/PriceItemSkeleton/PriceItemSkeleton";
import { UserQuantitySkeleton } from "../UserQuantity/UserQuantitySkeleton/UserQuantitySkeleton";
import { EnterpriseItemSkeleton } from "../EnterpriseItem/EnterpriseItemSkeleton/EnterpriseItemSkeleton";
import "./PriceItemsList.scss";
import { UserQuantity } from "../UserQuantity/UserQuantity";

export const PriceItemsList = ({ proxyCode, localCountry }) => {
  const { activeData } = useSelector((state) => state.content);
  const { lang } = useParams();
  const [priceItems, setPriceItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const localCache = useRef({});

  const controller = useMemo(() => new AbortController(), []);

  useEffect(() => () => {
      controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const fetchPriceItems = async (params, cacheKey) => {
    const loadingInterval = setTimeout(() => setIsLoading(true), 1200);
    try {
      const { data, status, code } = await ApiService.getPriceItems(params, controller);

      if (code === "ERR_CANCELED") {
        return;
      }

      if (status !== 200) {
        throw new Error();
      }

      const receivedData = data || [];

      localCache.current[cacheKey] = receivedData;
      setPriceItems(receivedData);
    } catch (e) {
      errorToast("Something went wrong", lang);
    } finally {
      clearTimeout(loadingInterval);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (() => {
      if (!localCountry?.id) return;

      const key = `${proxyCode}:${localCountry.id}`;
      const dataInCache = localCache?.current?.[key];

      if (dataInCache) {
        setPriceItems(dataInCache);
        return;
      }

      const params = {
        countryId: localCountry.id,
        currencyId: activeData?.currency?.id,
        proxyType: proxyCode
      };

      fetchPriceItems(params, key);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyCode, localCountry.id, activeData?.currency?.id]);

  return (
    <div className="price-items-list">
      <Container>
        <div className="price-items-list__container">
          { isLoading ? (
            <>
              {
                Array(4).fill("_").map((_, index) => (
                  <PriceItemSkeleton key={index} />
                ))
              }
              <UserQuantitySkeleton />
              <EnterpriseItemSkeleton />
            </>
          ) : (
            <>
              {priceItems.map((item) => (
                <PriceItem
                  key={item?.randomId}
                  proxyCode={proxyCode}
                  popular={item.popular}
                  quantity={item.count}
                  packageCountry={item.country}
                  initialPrice={{ usdAmount: item.usdAmount, usdPrice: item.usdPrice }}
                  initialRentPeriod={item.rentPeriodId}
                  localCountry={localCountry}
                  controller={controller}
                />
              ))}
              <UserQuantity
                key={proxyCode}
                proxyCode={proxyCode}
                localCountry={localCountry}
                controller={controller}
                packageCountry={priceItems[0]?.country}
              />
              <EnterpriseItem />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
