import { isSSR } from "./isSSR";

function getIframeDoc() {
  var iframe = document.getElementById("siq_chatwindow");
  return iframe ? iframe.contentDocument || iframe.contentWindow.document : null;
}

function addLargeButtons(channels) {
  let doc = getIframeDoc();
  if (!doc) return;
  let wrapper = doc.querySelector(".home-option-wrap");
  if (!wrapper) return;
  
  channels.forEach(({ id, text, link }) => {
    let buttonHtml = `
        <div data-id="${id}" class="posrel social-icon-optns curP flexC" role="listitem" aria-label="${text}" aria-disabled="false" tabindex="0" box-focus="" onclick="window.open('${link}', '_blank')">
          <em class="circle-btns ifont20 theme-dark-clr mR15 siq-${id}-vertical siq-social-bg"></em>
          <span class="font16 clr3 flexG">${text}</span>
          <em class="siqico-rarrow ifont20 clr6 size-20"></em>
        </div>
      `;
    wrapper.insertAdjacentHTML("beforeend", buttonHtml);
  });
}

function addSmallIcons(channels, reachUsText) {
  let doc = getIframeDoc();
  if (!doc) return;
  let wrapper = doc.querySelector(".home-option-wrap");
  if (doc.querySelector(".home-social-optns") || !wrapper) return;
  
  let iconsHtml = channels
    .map(
      ({ id, text, link }) => `
          <em tabindex="0" box-focus="" aria-label="${text}" class="siq-social-bg social-btns circle-btns theme-dark-clr curP siq-${id}" onclick="window.open('${link}', '_blank')"></em>
        `
    )
    .join("");
  let containerHtml = `
      <div class="posrel home-social-optns flexM">
        <span class="font16 w70 mL10">${reachUsText}</span>
        ${iconsHtml}
      </div>
    `;
  wrapper.parentNode.insertAdjacentHTML("beforeend", containerHtml);
}

function renderChannels(config) {
  if (!config || !config.list) {
    console.error("Social channels config is undefined or invalid:", config);
    return;
  }
  
  const { channelCount, list, reachUsText } = config;
  if (channelCount && list.length > channelCount) {
    addSmallIcons(list, reachUsText);
  } else {
    addLargeButtons(list);
  }
}

function initializeSocialChannels(config, initial) {
  let observer;
  if (initial && config) {
    renderChannels(config);
    observer && observer.disconnect();
  }
  const callback = (mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeType === 1 && node.querySelector(".home-option-wrap") && config) {
          renderChannels(config);
        }
      });
    });
  };
  observer = new MutationObserver(callback);
  let doc = getIframeDoc();
  if (doc) observer.observe(doc.body, { childList: true, subtree: true });
}

export const loadZohoChat = (lang) => {
  if (isSSR()) return;
  
  const chatId = "zsiqscript";
  const code = process.env.REACT_APP_ZOHO_SALESIQ_CODE;
  const CHAT_WAIT_TIME_SECONDS = 300;
  
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    ready: () => {
      window.$zoho.salesiq.chat.waittime(CHAT_WAIT_TIME_SECONDS);
      window.$zoho.salesiq.chat.logo("https://iili.io/3HZzy79.png");
      window.$zoho.salesiq.language(lang);
      
      window.$zoho.salesiq.socialChannels = {
        list: [
          { id: "telegram", text: "Chat on Telegram", link: "https://t.me/Proxyipv4_bot" }
        ],
        channelCount: 1,
        reachUsText: "Reach us on"
      };
    }
  };
  
  if (!document.getElementById(chatId)) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = chatId;
    script.src = `https://salesiq.zohopublic.eu/widget?wc=${code}`;
    script.defer = true;
    document.body.appendChild(script);
    
    script.onload = () => {
      setTimeout(() => initializeSocialChannels(window.$zoho.salesiq.socialChannels, true), 500);
    };
  }
};
