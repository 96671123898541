import { useSelector } from "react-redux";
import { useDispatchedActions } from "../../../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { CustomModal } from "../../../../../../../components/ui/CustomModal/CustomModal";
import { RegisterForm } from "./RegisterForm";

export const RegisterModal = () => {
  const { setRegisterModal } = useDispatchedActions();
  const { isRegisterModalOpened } = useSelector((state) => state.header);

  const { header: { modalSingUp } } = useTranslation();

  const handleClose = () => {
    setRegisterModal(!isRegisterModalOpened);
  };

  return (
    <CustomModal
      open={isRegisterModalOpened}
      onClose={handleClose}
      className="modal__login"
      header={modalSingUp.title}
    >
      <RegisterForm />
    </CustomModal>
  );

  //   if (isRegisterModalOpened) {
  //     return (
  //       <div id="owerlay" className="modal-owerlay" onClick={handleClose}>
  //         <div className="modal-container modal__login">
  //           <div className="modal__top">{modalSingUp.title}</div>

  // <FormProvider {...methods}>
  //   <form onSubmit={methods.handleSubmit(onSubmit)} className="modal__login-form">
  //     <ModalInput
  //       label={formElem.email.label}
  //       placeholder={formElem.email.placeholder}
  //       type="email"
  //       name="email"
  //     />
  //     <ModalInput
  //       label={formElem.password.label}
  //       placeholder={formElem.password.placeholder}
  //       type="password"
  //       name="password"
  //     />
  //     <ModalInput
  //       label={formElem.repeatPassword.label}
  //       placeholder={formElem.repeatPassword.placeholder}
  //       type="password"
  //       name="rePassword"
  //     />
  //     {!isPasswordTheSame && (
  //       <p className="modal__form-wrong">{formElem.wrongPassword}</p>
  //     )}
  //     {isCaptchaEnabled && (
  //       <div
  //         className={`modal__reCaptchaV2 modal__reCaptchaV2${
  //           useReCaptchaV3 ? "-display-none" : "-display-block"
  //         }`}
  //       >
  //         <ReCAPTCHA
  //           sitekey={content?.data[lang]?.google_captcha_v2_public_key}
  //           onChange={onChange}
  //         />
  //       </div>
  //     )}
  //     <Button
  //       type="submit"
  //       disabled={!useReCaptchaV3 && !tokenV2}
  //     >
  //       {modalSingUp.singUpBtn}
  //     </Button>
  //   </form>
  // </FormProvider>
  // <div className="modal__login-footer">
  //   <span> {modalSingUp.textLogin}</span>
  //   <button onClick={() => setLoginModal(true)} className="modal__action">
  //     {modalSingUp.loginBtn}
  //   </button>
  // </div>
  // <button
  //   className="modal-container-close_btn"
  //   onClick={() => setRegisterModal(!isRegisterModalOpened)}
  // >
  //   <IconSvg tag="close" />
  // </button>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
};
