import { forwardRef, useEffect, useState } from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export const CfCaptcha = forwardRef(({ setCfToken, style }, ref) => {
  const [isClient, setIsClient] = useState(true);

  const { lang } = useParams();
  const { skin } = useSelector((state) => state.header);
  const { content, isCaptchaEnabled } = useSelector((state) => state.content);

  const siteKey = content?.data?.[lang]?.cloud_captcha_site_key;

  useEffect(() => {
    setIsClient(true);
  }, []);

  const onError = (e) => {
    // eslint-disable-next-line no-console
    console.log(e);
  };

  return (
    <>
      {isClient && isCaptchaEnabled && siteKey && (
        <Turnstile
          ref={ref}
          style={{ alignSelf: "center", ...style }}
          onSuccess={setCfToken}
          onExpire={() => ref?.current?.reset()}
          onError={onError}
          siteKey={siteKey}
          action="login"
          retry="auto"
          scriptOptions={{
            appendTo: "body"
          }}
          options={{
            appearance: "interaction-only",
            theme: skin || "dark",
            size: "normal",
            language: lang
          }}
        />
      )}
    </>
  );
});
