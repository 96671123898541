import React from "react";
import SockJS from "sockjs-client";
import webSocketService from "../services/WebSocketService"
import Stomp from "webstomp-client";

export const webStompClient = () => {
    const baseUrl = process.env.REACT_APP_CRM_API_URL;

    // let socket = new SockJS(`${baseUrl}/api/websocket/outer`, null, {transports: webSocketService.getTransportProtocols});
    let socket = new SockJS(`${baseUrl}/api/websocket/outer`, null, {transports: webSocketService.getTransportProtocols});
    let stompClient = Stomp.over(socket);

    // if (isProd) {
    //     stompClient.debug = () => {};
    // }
    
    stompClient.debug = () => {};
    
    stompClient.connect({}, (frame) => {
    }, (error) => {
        console.log(error);
    })
    return stompClient;
}

export const StompClientContext = React.createContext(null);
