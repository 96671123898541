import {Route} from "react-router-dom";

export const getPreparedRoutes = (routes) =>
  routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      hydrateFallbackElement={<>Loading...</>}
      lazy={async () => {
        const { default: Component } = await route.component();

        return {
          element: <Component tag={route.tag} />
        };
      }}
    />
  ));
