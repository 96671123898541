import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { MobileProxyItem } from "./MobileProxyItem";

// const crmBreakpoints = {
//   320: {
//     spaceBetween: 15,
//     slidesPerView: 1.25,
//   },
//   460: {
//     spaceBetween: 15,
//     slidesPerView: 1.5,
//   },
//   767: {
//     spaceBetween: 30,
//     slidesPerView: 2.5,
//   },
// };

export const MobileProxyList = ({ data, handlerClick }) => (
  <Swiper
    className="mobile-list"
    spaceBetween={12}
    // speed={500}
    slidesPerView={data?.length > 1 ? 1.1 : 1}
    // autoplay={{ delay: 3000 }}
    // pagination={{ clickable: true }}
    wrapperTag="ul"
    watchOverflow
    navigation
    pagination={{ clickable: true }}
    // loop
    modules={[Navigation, Pagination, Autoplay]}
    // breakpoints={crmBreakpoints}
  >
    {data && data?.length > 0
      ? data?.map?.((item) => (
        <SwiperSlide
          className="mobile-list__item"
          tag="li"
          style={{ height: "auto" }}
          key={item.id}
        >
          <MobileProxyItem data={item} handlerClick={handlerClick} />
        </SwiperSlide>
      ))
      : null}
  </Swiper>
);
