import { ApiService } from "../../services/ApiService";
import { errorToast } from "./customToast.helper";

export const calculatePrice = async (
  setResult, searchParams, lang, setIsLoading, controller
) => {
  const loadingInterval = setTimeout(() => setIsLoading(true), 1200);
  try {
    const response = await ApiService.calculateDesktopPrice(searchParams, controller);
  
    if (response?.code === "ERR_CANCELED") {
      return;
    }
    
    if (response?.status !== 200) {
      throw response;
    }
  
    setResult(response.data);

  } catch (err) {
    errorToast(err?.message || err, lang);
  } finally {
    clearTimeout(loadingInterval);
    setIsLoading(false);
  }
};
