import { Navigate, Route, redirect } from "react-router-dom";
import i18next from "i18next";
import { AppLayout } from "../layout/AppLayout";
import { IndexLayout } from "../layout/indexLayout";
import PerfectMoney from "../components/common/PerfectMoney/PerfectMoney";
import WebMoney from "../components/common/WebMoney/WebMoney";
import { getPreparedRoutes } from "../utils/helpers/getPreparedRoutes";
import { LOCALS } from "../utils/constants/locals";
import { appRoutes, cabinetRoutes, otherRoutes } from "./routes";

export const getAppRoutes = ({ lang = "en" }) => (
    <>
      <Route
        element={<IndexLayout />}
        loader={({ params }) => {
          const locales = i18next.languages || Object.values(LOCALS);

          if (params.lang && locales.indexOf(params.lang) === -1) {
            return redirect(`/${lang}/`);
          }

          return null;
        }}
      >
        <Route element={<AppLayout />}>
          {getPreparedRoutes(cabinetRoutes())}
        </Route>
        <Route element={<AppLayout />}>
          {getPreparedRoutes(otherRoutes())}
        </Route>
        <Route element={<AppLayout />}>
          {getPreparedRoutes(appRoutes())}
        </Route>
      </Route>
      <Route
        path="/process/payment/webmoney/:paymentId"
        element={<WebMoney />}
      />
      <Route
        path="/process/payment/perfectMoney/:paymentId"
        element={<PerfectMoney />}
      />
      <Route
        path="*"
        element={<Navigate to={`/${lang}/`} />}
      />
    </>
  );
