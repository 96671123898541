import { useEffect } from "react";
import { Actions } from "../Actions/Actions";
import { ScrollNav } from "../ScrollNav/ScrollNav";

import "./DesktopMenu.scss";
import { DesktopDropDown } from "../DesktopDropDown";

export const DesktopMenu = ({ loginFormHandler }) => {
  useEffect(() => {
    document.body.classList.remove("disable-scroll");
  }, []);

  return (
    <div className="desktop-menu">
      <div className="media__min-width-993">
        <nav className="header-nav">
          <DesktopDropDown />
          <ScrollNav />
        </nav>
      </div>
      <div className="media__min-width-993">
        <Actions loginFormHandler={loginFormHandler} />
      </div>
    </div>
  );
};
