import { $api } from "./AxiosInstance";

export class ApiService {
  static login(params) {
    return $api.post("/api/login", null, { params });
  }

  static async logout() {
    const result = await $api
      .get("/api/logout")
      .then(() => true)
      .catch(() => false);
    return result;
  }

  static cacheSSR(id) {
    return $api.get(`/ssr/cache/${id}`);
  }

  static sendEmailInBase(params) {
    return $api.get("/api/front/filling/form", { params });
  }

  static createPayment(params) {
    return $api.post("/api/payment/create", params);
  }

  static clientRegister(params) {
    return $api.post("/api/users/register", params);
  }

  static userAuthUserProfile() {
    return $api.get("/api/users/auth/profile");
  }

  static userConfirmedOrders() {
    return $api.get("/api/ecommerce/get");
  }

  static userDeleteConfirmedOrders(ids) {
    return $api.post("/api/ecommerce/delete", { paymentIds: ids });
  }

  static webmoneyInfo(id) {
    return $api.get("/api/payment/webmoney", { params: { id } });
  }

  static perfectMoneyInfo(id) {
    return $api.get("/api/payment/perfectMoney", { params: { id } });
  }

  static getApiDataKey(controller) {
    return $api.get("/api/clientApi/getSettings", {
      signal: controller?.signal,
    });
  }

  static getNewApiKey() {
    return $api.post("/api/clientApi/generateNewToken");
  }

  static getFAQ(localeCode) {
    return $api.get("/api/front/faq/v2", { params: { localeCode } });
  }
  
  static getFAQTypesV3(localeCode) {
    return $api.get("/api/front/faq/types/v3", { params: { localeCode } });
  }

  static replaceIp(params) {
    return $api.post("/api/ip/request/replace", params);
  }

  static saveSettingsUserAPI(params) {
    return $api.post("/api/clientApi/updateSettings", params);
  }

  static getAllUserTableData(
    page = 0,
    size = 10,
    params,
    sort,
    controller,
    filterByDateParams
  ) {
    return $api.post(
      "/api/user/cabinet/v2/ip",
      {
        page,
        size,
        params,
        sort,
        ...filterByDateParams,
      },
      {
        signal: controller?.signal,
      }
    );
  }

  static getFilterParams(proxyType, folderId, controller) {
    const params = folderId ? { proxyType, folderId } : { proxyType };

    return $api.get("/api/user/cabinet/ip/filter/date", {
      signal: controller?.signal,
      params,
    });
  }

  static getMobileDaysWithPrices(params) {
    return $api.post("/api/front/mobileTariff/price/select", params);
  }

  static getAccessModalData() {
    return $api.get("/api/ip/auth/info/v2");
  }

  static removeAccessIp(params) {
    return $api.post("/api/ip/auth/delete/ip", params);
  }

  static changeAccessData(params) {
    return $api.post("/api/ip/auth/change/v2", params);
  }

  static checkAccessLogin(login) {
    return $api.get("/api/ip/auth/check/login", { params: { login } });
  }

  static editUserTableData(params) {
    return $api.post("/api/user/cabinet/ip/edit", params);
  }

  static sendFeedback(params) {
    return $api.post("/api/email/feedback", params);
  }

  static editUserTableDataFolder(params) {
    return $api.post("/api/user/cabinet/ip/edit/setFolder", params);
  }

  static editSelectedUserTableData(params) {
    return $api.post("/api/user/cabinet/ip/edit/list", params);
  }

  static checkIpForAutoExtending(params) {
    return $api.post("/api/user/cabinet/ip/check/can/autoExtending", params);
  }

  static userBalanceTable(page = 0, size = 10, controller) {
    return $api.get("/api/front/balanceHistory", {
      signal: controller?.signal,
      params: { page, size },
    });
  }

  static userOrderItemsIPv6(id) {
    return $api.get("/api/ip/findIpv6ByIpId", { params: { id } });
  }

  static getAllUserOrders(proxyType) {
    return $api.get("/api/user/cabinet/ip/all", { params: { proxyType } });
  }

  static getOrderByProxyCode(proxyType) {
    return $api.get("/api/user/cabinet/orders/byProxyType", { params: { proxyType } })
  }
  
  static getAllUserOrdersByFolder(
    proxyType,
    ipAddressFolderId,
    filterParams,
    filterByDateParams
  ) {
    return $api.post("/api/user/cabinet/ip/getIdsByParams", {
      params: {
        ipAddressFolderId,
        proxyType,
        ...filterParams,
      },
      ...filterByDateParams,
    });
  }

  static getFullContent(locale) {
    return $api.get("/api/front/content", {
      params: {
        locale,
      },
    });
  }

  static getFullContentSSR(baseURL, locale) {
    return $api.get(`${baseURL}/api/front/content`, {
      params: {
        locale,
      },
    });
  }
  
  static getFaqByParamsSSR(baseURL, proxyType, countryUrl) {
    return $api.get(`${baseURL}/api/front/faq/v3`, {
      params: {
        proxyType,
        countryUrl
      }
    })
  }
  
  static getFaqByParams(proxyType, countryUrl) {
    return $api.get("/api/front/faq/v3", {
      params: {
        proxyType,
        countryUrl
      }
    })
  }

  static getReviews(localeCode, size, page) {
    return $api.get("/api/front/siteReview/page", {
      params: { localeCode, size, page },
    });
  }

  static getAllSelectedIps(ids) {
    return $api.post("/api/user/cabinet/get/ips/by/ids", { ids });
  }

  static getRentPeriods() {
    return $api.get("/api/front/rentPeriod");
  }

  static getRentPeriodsSSR(baseURL) {
    return $api.get(`${baseURL}/api/front/rentPeriod`);
  }

  static getAllCountries() {
    return $api.get("/api/front/country");
  }

  static getAllCountriesSSR(baseURL, userIp) {
    return $api.get(`${baseURL}/api/front/country`, { params: { userIp } });
  }

  static getMobileProxy(currencyId) {
    return $api.get("/api/front/mobileTariff", {
      params: {
        currencyId,
      },
    });
  }

  static getMobileProxySSR(baseURL, currencyId) {
    return $api.get(`${baseURL}/api/front/mobileTariff`, {
      params: {
        currencyId,
      },
    });
  }

  static getFrontConfig() {
    return $api.get("/api/front/config");
  }

  static getFrontConfigSSR(baseURL, userIp) {
    return $api.get(`${baseURL}/api/front/config`, { params: { userIp } });
  }

  static calculateDesktopPrice(params, controller) {
    return $api.post("/api/price/calculate", params, {
      signal: controller?.signal,
    });
  }

  static calculateMobilePrice(params) {
    return $api.post("/api/price/calculate/mobile", params);
  }

  static extendProxies(params) {
    return $api.post("/api/price/calculate/extend", params);
  }

  static createPaymentForExtendProxies(params) {
    return $api.post("/api/payment/extend", params);
  }

  static createPaymentForBalance(params) {
    return $api.post("/api/payment/replenishmentBalance", params);
  }

  static getPaymentSystems(params) {
    return $api.post("/api/front/v2/paymentSystem", params);
  }

  static getRecoveryPasswordLink(params) {
    return $api.post("/api/users/recoveryPasswordLink", params);
  }

  static getRecoveryPassword(hash, newPassword) {
    return $api.post("/api/users/recoveryPassword", {
      hash,
      newPassword,
    });
  }

  static changePassword(oldPassword, newPassword) {
    return $api.post("/api/users/changePassword", {
      oldPassword,
      newPassword,
    });
  }

  static changeEmail(newEmail, password) {
    return $api.post("/api/users/changeEmail", {
      newEmail,
      password,
    });
  }

  static getBalance(controller) {
    return $api.get("/api/balance/getBalance", { signal: controller?.signal });
  }

  static getTelegramInfo(controller) {
    return $api.get("/api/telegram", { signal: controller?.signal });
  }

  static setTelegramInfo(notificationEnabled) {
    return $api.post("/api/telegram", { notificationEnabled });
  }

  static getDistributionInfo(controller, localeCode) {
    return $api.get("/api/user/emailInfo/v2/get", {
      signal: controller?.signal,
      params: { localeCode },
    });
  }

  static setDistributionInfo(enabledCodes) {
    return $api.post("/api/user/emailInfo/v2/set", { enabledCodes });
  }

  static getAllClientIpsByProxyType(proxyType) {
    return $api.get("/api/ip/byPrincipal", { params: { proxyType } });
  }

  static getSelectedClientIps(ids) {
    return $api.post("/api/user/cabinet/ip/getByIds", { ids });
  }

  static tokenStatus() {
    return $api.get("/api/captcha/status");
  }

  static getSeoInfo(params) {
    return $api.get("/api/front/siteSeo/byCountryIdAndLocaleIdAndProxyType", {
      params,
    });
  }

  static getSeoInfoSSR(baseURL, params) {
    return $api.get(
      `${baseURL}/api/front/siteSeo/byCountryIdAndLocaleIdAndProxyType`,
      {
        params,
      }
    );
  }

  static getProject(id) {
    return $api.get("/api/admin/project", { params: { id } });
  }

  static projectSearch(searchCriteria) {
    return $api.get("/api/front/projectView/search", {
      params: { searchCriteria },
    });
  }

  static isAuth() {
    return $api.get("/api/users/user/v2/authenticated");
  }

  // static saveToken(email, token) {
  //   return $api.put("/api/save/token", {
  //     email,
  //     token
  //   });
  // }

  static getEcommersInfo(id) {
    return $api.post(`/api/payment/getEcommerceInfo/${id}`);
  }

  static getFolders(controller) {
    return $api.get("/api/ip/folder", { signal: controller?.signal });
  }

  static addFolder(name, proxyType) {
    return $api.put("/api/ip/folder", { name, proxyType });
  }

  static editFolderName(id, name) {
    return $api.post("/api/ip/folder", { id, name });
  }

  static deleteFolder(id) {
    return $api.delete("/api/ip/folder", { params: { id } });
  }

  static getPriceItems(params, controller) {
    return $api.post("/api/front/proxy/package", params, {
      signal: controller?.signal,
    });
  }
  static sendEnterpriseSuggestion(params) {
    return $api.post("/api/email/create/request", params);
  }
  
  static sendClientGoal(params) {
    // return $api.post("https://stage.home-stash.house/api/ws/v1/client/change/project", params)
    return $api.post("/api/ws/v1/client/change/project", params)
  }
  
  static sendGoal(params) {
    return $api.post("/api/order/changeProject", params);
  }
  
  static getNotifications(params) {
    return $api.get("/api/front/web/v2/notifications", { params });
  }
  
  static removeNotifications() {
    return $api.post("/api/front/web/notifications/delete");
  }
  
  static setNotificationsAsRead(params) {
    return $api.post("/api/front/web/notifications/v2/read", params);
  }
  
  static getPaddleData() {
    return $api.get("/api/paddle/subscription/userdata");
  }
  
  static sendCodeForRemovingUser() {
    return $api.get("/api/users/account/delete/sendEmail");
  }
  
  static getAllAutoextends() {
    return $api.get("/api/autoextend");
  }
  
  static getAutoExtendPrice(params) {
    return $api.post("/api/autoextend/price/calculate", params);
  }
  
  static removeAcc(params) {
    return $api.post("/api/users/account/delete", params)
  }
  
  static createExportLink(params) {
    return $api.post("/api/export/createLink", params)
  }
  
  static getExportLink() {
    return $api.get("/api/export/getLink")
  }
}
