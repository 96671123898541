import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";

export const Animation = ({ animationName }) => {
  const { RiveComponent } = useRive({
    src: `/animations/${animationName}.riv`,
    stateMachines: "State Machine 1",
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.Center
    }),
    autoplay: true
  });

  return <RiveComponent />;
};
