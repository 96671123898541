import { FormProvider, useForm } from "react-hook-form";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../../../ui/Buttons/Button/Button";
import { ModalInput } from "../../../../../../ui/ModalInput/ModalInput";
import { emailPattern } from "../../../../../../../utils/constants/emailPattern";
import { passwordPattern } from "../../../../../../../utils/constants/passwordPattern";
import { FormCheckBox } from "../../../../../../ui/CheckBoxes/FormCheckBox";
import { CfCaptcha } from "../../../../../CfCaptcha/CfCaptcha";
import { ButtonLoader } from "../../../../../../ui/ButtonLoader/ButtonLoader";
import { ApiService } from "../../../../../../../services/ApiService";
import { errorToast } from "../../../../../../../utils/helpers/customToast.helper";
import { useDispatchedActions, useTranslation } from "../../../../../../../hooks";

export const LoginForm = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const matchCabinet = useMatch("/:lang/dashboard");
  // const reCaptchaRef = useRef();
  // Dispatch
  const {
    setLoginModal,
    setRecoveryModal,
    setRegisterModal,
    setUserAuthData
  } = useDispatchedActions();

  const { fiveDollarLogick } = useSelector((state) => state.content.proxies);
  const { isLoginModalOpened } = useSelector((state) => state.header);

  const [isLoading, setIsLoading] = useState(false);

  const cfCaptchaRef = useRef(null);

  const {
    cabinet: { profile },
    header: { modalCabinet, formElem },
    notifications
  } = useTranslation();

  const methods = useForm({
    mode: "onChange"
  });

  const { errors } = methods.formState;

  const [disabled, setDisabled] = useState(false);

  const handleLogin = async () => {
    let token = "";

    setDisabled(true);
    setIsLoading(true);

    if (cfCaptchaRef?.current) {
      token = await cfCaptchaRef.current?.getResponsePromise();
    }

    const data = methods.getValues();
    try {
      const params = {
        username: data.email,
        password: data.password,
        rememberMe: data.remember,
        captchaToken: token
      };
      const response = await ApiService.login(params);

      if (response.response && response.response.status !== 200) {
        setDisabled(false);
        throw notifications.loginError;
      }

      if (response?.status === 200) {
        const userProfileResponse = await ApiService.userAuthUserProfile();
        if (
          userProfileResponse.response &&
          userProfileResponse.response.status !== 200
        ) {
          setDisabled(false);
          throw notifications.loginError;
        }
        if (userProfileResponse?.data) {
          localStorage.setItem(
            "client_profile",
            JSON.stringify(userProfileResponse?.data)
          );
          setLoginModal(!isLoginModalOpened);
          setUserAuthData({
            isAuth: true,
            email: userProfileResponse?.data.email
          });
          cfCaptchaRef?.current?.reset();

          //five Dollar Logick
          if (fiveDollarLogick?.enabled && fiveDollarLogick?.use) {
            setDisabled(false);
            navigate(`/${lang}/dashboard/?tab=balance`);
          } else {
            setDisabled(false);
            if (!matchCabinet) {
              navigate(`/${lang}/dashboard/`);
            }
          }
        } else {
          setDisabled(false);
          throw notifications.loginError;
        }
      } else {
        setDisabled(false);
        throw notifications.loginError;
      }
    } catch (err) {
      errorToast(err.message || err, lang);
      setDisabled(false);
    } finally {
      setIsLoading(false);
      // if (reCaptchaRef.current) {
      //   reCaptchaRef.current.reset();
      // }
    }
  };

  const onSubmit = () => {
    // if (
    //   isCaptchaEnabled &&
    //   reCaptchaRef.current &&
    //   content?.data[lang]?.google_captcha_v2_public_key
    // ) {
    //   reCaptchaRef.current.execute();
    // } else {
    //
    // }
    handleLogin();
  };

  const isInvalidEmail = errors.email?.type === "pattern";
  const isLessThanSixChars =
    errors.password?.type === "validate" ||
    errors.rePassword?.type === "validate";

  const isContainSpaces =
    errors.password?.type === "pattern" ||
    errors.rePassword?.type === "pattern";

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="modal__login-form"
          noValidate={true}
        >
          <ModalInput
            label={formElem.email.label}
            // placeholder={formElem.email.placeholder}
            type="email"
            name="email"
            placeholder="email"
            watch={methods.watch}
            rules={{
              required: true,
              pattern: emailPattern
            }}
          />
          {isInvalidEmail && (
            <p className="modal__form-wrong modal__login-hint">
              {formElem.invalidEmail}
            </p>
          )}
          <ModalInput
            label={formElem.password.label}
            // placeholder={formElem.password.placeholder}
            type="password"
            name="password"
            placeholder="password"
            watch={methods.watch}
            rules={{
              validate: (value) => value?.length >= 6,
              pattern: passwordPattern
            }}
          />
          {isContainSpaces && (
            <p className="modal__form-wrong modal__login-hint">
              {profile?.wrongPasswordChars}
            </p>
          )}
          {isLessThanSixChars && (
            <p className="modal__form-wrong modal__login-hint">
              {profile?.wrongPasswordLength}
            </p>
          )}
          <div className="modal__login-row">
            <FormCheckBox
              name="remember"
              // defaultValue
              label={modalCabinet?.remember}
              // rules={{
              //   required: true
              // }}
            />
            <button
              type="button"
              className="modal__login-restore"
              onClick={() => {
                setRecoveryModal(true);
              }}
            >
              {modalCabinet.recoveryBtn}
            </button>
          </div>
          {/* eslint-disable-next-line no-empty-function */}
          <CfCaptcha ref={cfCaptchaRef} setCfToken={() => {}} />
          {/*{isCaptchaEnabled &&*/}
          {/*  content?.data[lang]?.google_captcha_v2_public_key && (*/}
          {/*    <ReCAPTCHA*/}
          {/*      ref={reCaptchaRef}*/}
          {/*      // theme="dark"*/}
          {/*      size="invisible"*/}
          {/*      sitekey={content?.data[lang]?.google_captcha_v2_public_key}*/}
          {/*      onChange={handleLogin}*/}
          {/*    />*/}
          {/*  )}*/}
          <Button type="submit" disabled={disabled}>
            {isLoading ? <ButtonLoader /> : modalCabinet.enterBtn}
          </Button>
        </form>
      </FormProvider>
      <div className="modal__login-footer">
        <span> {modalCabinet.textSignUp}</span>
        <Button
          onClick={() => setRegisterModal(true)}
          className="modal__action"
        >
          {modalCabinet.signUpBtn}
        </Button>
      </div>
    </>
  );
};
