import MediaQuery from "react-responsive";
import Select, { components } from "react-select";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Pagination } from "../../../../components/common/Pagination/Pagination";
import { Button } from "../../../../components/ui/Buttons/Button/Button";
import { useTranslation } from "../../../../hooks";
import { rowPerPageOptions } from "../../../../utils/constants";
import { isSSR } from "../../../../utils/helpers";
import { moveTo } from "../../../../utils/helpers/moveTo";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import { getBorderColor } from "../../../../utils/helpers/getBorderColor";

export const FeedbackPagination = ({ reviewsData, localCurrentPage, setLocalCurrentPage, localSize, setLocalSize, setPositionY }) => {
  const { lang } = useParams();
  const { select: { noOptionsMessage }, cabinet: { balance }, buttons: { showMore: showMoreLabel } } = useTranslation();
  const { skin } = useSelector((state) => state.header);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.select.setValue({ label: `${localSize} ${balance?.lines}`, value: localSize });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, localSize]);

  const handlePageChange = (page) => {
    setLocalCurrentPage(page);
    moveTo(0, 0);
  };

  const defineRowsPerPageOptions = () =>
    [
      ...rowPerPageOptions.filter(
        (item) => item < reviewsData?.totalElements
      ),
      reviewsData?.totalElements
    ].map((item) => ({ label: `${item} ${balance?.lines}`, value: item }));

  const handlePerRowsChange = (perPage) => {
    setLocalCurrentPage(1);
    setLocalSize(perPage.value);
  };

  const showMore = () => {
    if (isSSR()) {
      return;
    }

    setLocalSize((current) => current + 10);

    setPositionY(window.scrollY);
  };

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <IconSvg tag="triangleDown12" />
    </components.DropdownIndicator>
  );

  return (
    <div className="feedback-list__pagination">
      <MediaQuery minWidth={993}>
        <div className="history__list__footer">
          <Pagination
            totalPages={reviewsData?.totalPages}
            currentPage={localCurrentPage}
            onPageChange={handlePageChange}
            // hideOnSinglePage={true}
          />
          <Select
            className={"modal__select"}
            ref={ref}
            classNamePrefix="modal__select"
            options={defineRowsPerPageOptions()}
            defaultValue={{ label: `${localSize} ${balance?.lines}`, value: localSize }}
            menuPlacement="bottom"
            isSearchable={false}
            onChange={handlePerRowsChange}
            noOptionsMessage={() => noOptionsMessage}
            components={{ DropdownIndicator }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: getBorderColor(state, skin)
              })
            }}
          />
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={992}>
        <Button
          tab="dark-gradient"
          onClick={showMore}
          disabled={localSize >= reviewsData?.totalElements}
        >
          {showMoreLabel}
        </Button>
      </MediaQuery>
    </div>
  );
};
