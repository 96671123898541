import { useSelector } from "react-redux";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { useDispatchedActions } from "../../../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { CustomModal } from "../../../../../../../components/ui/CustomModal/CustomModal";
import { LoginForm } from "./LoginForm";

export const LoginModal = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const matchCabinet = useMatch("/:lang/dashboard");

  const { setLoginModal, setUseFiveDollarLogick } = useDispatchedActions();

  const { isLoginModalOpened } = useSelector((state) => state.header);
  const { isAuth } = useSelector((state) => state.content.user);

  const { header: { modalCabinet } } = useTranslation();

  const handleClose = () => {
    if (matchCabinet && !isAuth) {
      navigate(`/${lang}/`);
    }
    setUseFiveDollarLogick(false);
    setLoginModal(false);
  };

  return (
    <CustomModal
      open={isLoginModalOpened && !isAuth}
      onClose={handleClose}
      className="modal__login"
      header={modalCabinet.title}
    >
      <LoginForm />
    </CustomModal>
  );
  // if (isLoginModalOpened && !isAuth) {
  //   return (
  //     <div id="owerlay" className="modal-owerlay" onClick={handleClose}>
  //       <div className="modal-container modal__login">
  //         <div className="modal__top">{modalCabinet.title}</div>
  //         <FormProvider {...methods}>
  //           <form
  //             onSubmit={methods.handleSubmit(onSubmit)}
  //             className="modal__login-form"
  //           >
  //             <ModalInput
  //               label={formElem.email.label}
  //               placeholder={formElem.email.placeholder}
  //               type="email"
  //               name="email"
  //             />
  //             <ModalInput
  //               label={formElem.password.label}
  //               placeholder={formElem.password.placeholder}
  //               type="password"
  //               name="password"
  //             />
  //             <div className="modal__login-row">
  //               <FormCheckBox
  //                 name="policy"
  //                 // defaultValue
  //                 label={modalCabinet?.remember}
  //                 rules={{
  //                   required: true,
  //                 }}
  //               />
  //               <span
  //                 className="modal__login-restore"
  //                 onClick={() => setRecoveryModal(true)}
  //               >
  //                 {modalCabinet.recoveryBtn}
  //               </span>
  //             </div>
  //             <Button type="submit" disabled={disabled}>
  //               {modalCabinet.enterBtn}
  //             </Button>
  //           </form>
  //         </FormProvider>
  //         <div className="modal__login-footer">
  //           <span> {modalCabinet.textSignUp}</span>
  //           <button
  //             onClick={() => setRegisterModal(true)}
  //             className="modal__action"
  //           >
  //             {modalCabinet.signUpBtn}
  //           </button>
  //         </div>

  //         <button
  //           className="modal-container-close_btn"
  //           onClick={() => {
  //             setUseFiveDollarLogick(false);
  //             setLoginModal(!isLoginModalOpened);
  //           }}
  //         >
  //           <IconSvg tag="close" />
  //         </button>
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return null;
  // }
};
