import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { useSelector } from "react-redux";

import { useTranslation } from "../../../hooks/useTranslation";

import "./Proxy.scss";

// const crmBreakpoints = {
//   320: {
//     spaceBetween: 15,
//     slidesPerView: 1.25,
//   },
//   460: {
//     spaceBetween: 15,
//     slidesPerView: 1.5,
//   },
//   767: {
//     spaceBetween: 30,
//     slidesPerView: 2.5,
//   },
// };

export const Proxy = (props) => {
  //  **Props
  // eslint-disable-next-line no-empty-function
  const { localProxyType, setLocalProxyType, setCountryParams = () => {} } = props;
  // **State
  const { proxies } = useSelector((state) => state.content);
  const { proxyNav } = useTranslation().header.menu;

  const setProxyType = (proxyCode) => {
    if (proxyCode === localProxyType) return;

    setLocalProxyType(proxyCode);
    setCountryParams({
      id: "",
      code: ""
    });
  };

  return (
    <>
      <div className="proxies__container-min-993">
        <ul className="proxies__advantages">
          {proxies?.enabled.map((obj) => (
            <li
              key={obj?.value}
              className={`proxies__advantage  ${
                obj?.code === localProxyType ? "proxies__advantage--active" : ""
              }`}
            >
              <button
                className={`proxies__button ${
                  obj?.code === localProxyType ? "proxies__button--active" : ""
                }`}
                onClick={() => setProxyType(obj?.code)}
              >
                {obj?.code !== "MOBILE"
                  ? `${proxyNav?.subName} ${obj?.label}`
                  : proxyNav?.subNameMobile}
              </button>
              {/*<Link*/}
              {/*  className={`proxies__link ${*/}
              {/*    obj?.code === localProxyType ? "proxies__link--active" : ""*/}
              {/*  }`}*/}
              {/*  to={`/${lang}/${obj?.code === "IPv4" ? "" : `${obj?.code}/`}`}*/}
              {/*  // onClick={() => setLocalProxyType(obj?.code)}*/}
              {/*>*/}
              {/*  {obj?.code !== "MOBILE"*/}
              {/*    ? `${proxyNav?.subName} ${obj?.label}`*/}
              {/*    : proxyNav?.subNameMobile}*/}
              {/*</Link>*/}
            </li>
          ))}
        </ul>
      </div>
      <div className="proxies__container-max-992">
        <Swiper
          className="proxies__advantages"
          spaceBetween={32}
          // speed={500}
          slidesPerView="auto"
          // autoplay={{ delay: 3000 }}
          // pagination={{ clickable: true }}
          wrapperTag="ul"
          watchOverflow
          navigation
          // loop
          modules={[Navigation, Pagination, Autoplay]}
          // breakpoints={crmBreakpoints}
        >
          {typeof proxies?.enabled === "object" &&
            proxies?.enabled.map((obj, index) => (
              <SwiperSlide
                className={`proxies__advantage  ${
                  obj?.code === localProxyType
                    ? "proxies__advantage--active"
                    : ""
                }`}
                tag="li"
                style={{ height: "auto" }}
                key={index}
              >
                {/* <button
                  className={`proxies__button ${
                    obj?.code === localProxyType
                      ? "proxies__button--active"
                      : ""
                  }`}
                  onClick={() => setLocalProxyType(obj?.code)}
                >
                  {obj?.code !== "MOBILE"
                    ? `${proxyNav?.subName} ${obj?.label}`
                    : proxyNav?.subNameMobile}
                </button> */}
                <Link
                  className={`proxies__link ${
                    obj?.code === localProxyType ? "proxies__link--active" : ""
                  }`}
                  // to={`/${lang}/${obj?.code === "IPv4" ? "" : `${obj?.code}/`}`}
                  onClick={() => setProxyType(obj?.code)}
                >
                  {obj?.code !== "MOBILE"
                    ? `${proxyNav?.subName} ${obj?.label}`
                    : proxyNav?.subNameMobile}
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      {/*{isSSR() && (*/}
      {/*  <ul className="proxy__proxies">*/}
      {/*    {proxies?.enabled.map((obj) => (*/}
      {/*      <li*/}
      {/*        key={obj?.value}*/}
      {/*        className={`proxy__proxy ${*/}
      {/*          obj?.code === localProxyType ? "proxy__proxy--active" : ""*/}
      {/*        }`}*/}
      {/*      >*/}
      {/*        /!* <button*/}
      {/*          className={`proxies__button ${*/}
      {/*            obj?.code === localProxyType ? "proxies__button--active" : ""*/}
      {/*          }`}*/}
      {/*          onClick={() => setLocalProxyType(obj?.code)}*/}
      {/*        >*/}
      {/*          {obj?.code !== "MOBILE"*/}
      {/*            ? `${proxyNav?.subName} ${obj?.label}`*/}
      {/*            : proxyNav?.subNameMobile}*/}
      {/*        </button> *!/*/}
      {/*        <Link*/}
      {/*          className={`proxies__link ${*/}
      {/*            obj?.code === localProxyType ? "proxies__link--active" : ""*/}
      {/*          }`}*/}
      {/*          to={`/${lang}/${obj?.code === "IPv4" ? "" : `${obj?.code}/`}`}*/}
      {/*          // onClick={() => setLocalProxyType(obj?.code)}*/}
      {/*        >*/}
      {/*          {obj?.code !== "MOBILE"*/}
      {/*            ? `${proxyNav?.subName} ${obj?.label}`*/}
      {/*            : proxyNav?.subNameMobile}*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*    ))}*/}
      {/*  </ul>*/}
      {/*)}*/}
    </>
  );
};
