import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Container } from "../../../../components/ui/Container/Container";
import { useTranslation } from "../../../../hooks/useTranslation";
import { FaqAccordion } from "../../../../components/common/Faq/FaqAccordion/FaqAccordion";
import { FaqTitle } from "../../../../components/common/Faq/FaqTitle/FaqTitle";
import "./FaqSection.scss";
import { classNames } from "../../../../utils/helpers/classNames";
import { useIsThemeDark } from "../../../../hooks/useIsThemeDark";
import { errorToast } from "../../../../utils/helpers/customToast.helper";
import { ApiService } from "../../../../services/ApiService";
import { useDispatchedActions } from "../../../../hooks";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";

export const FaqSection = () => {
  // **Props
  const { lang, country } = useParams();
  const [searchParams] = useSearchParams();
  const s = searchParams.get("s");
  const countryInSearch = searchParams.get("country");

  // ** Redux State
  const { activeData, faq: faqData } = useSelector((state) => state.content);
  const { faq } = useTranslation();

  const isThemeDark = useIsThemeDark();

  const { setFaqByParams } = useDispatchedActions();

  // *Ref
  const refFaq = useRef(null);
  const isMounted = useRef(false);

  useEffect(() => {
    if (s === "faq" && refFaq?.current?.clientHeight) {
      refFaq.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [s]);

  const fetchFaqs = async (proxyType, countryUrl) => {
    if (!proxyType) return;

    try {
      const { data, status } = await ApiService.getFaqByParams(proxyType, countryUrl);

      if (status !== 200) {
        throw new Error();
      }

      setFaqByParams(data);
    } catch (e) {
      errorToast("Something went wrong", lang);
    }
  };

  useEffect(() => {
    if (!isMounted.current && faqData.isLoaded) {
      isMounted.current = true;
      return;
    }

    fetchFaqs(activeData?.proxy?.code, country || countryInSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, countryInSearch, activeData?.proxy?.code]);

  const filteredFaqs = faqData?.data?.[lang] || [];

  return (
    !!filteredFaqs.length && (
      <section
        ref={refFaq}
        className={classNames("faq", { "faq--dark": isThemeDark })}
        id="faq"
      >
        <Container>
          <div className="faq__container">
            <div className="faq__box-info">
              <FaqTitle />
              <p className="faq__description">
                {faq?.description}
              </p>
              <Link to={`/${lang}/contacts/`} className="faq__ask">
                {faq?.ask}
                <IconSvg tag="arrow" />
              </Link>
            </div>
            <FaqAccordion items={filteredFaqs} />
            {/* <MediaQuery minWidth={993}>
              <FaqAccordion items={content?.data[lang]?.faq} />
            </MediaQuery> */}
          </div>
        </Container>
        {/* <MediaQuery maxWidth={992}>
          <FaqAccordion items={content?.data[lang]?.faq} />
        </MediaQuery> */}
      </section>
    )
  );
};
