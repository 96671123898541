import "../assets/styles/ReactToastify.css";
import { useEffect, useState } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Footer } from "../components/common/Footer/Footer";
import { Header } from "../components/common/Header/Header";
import { MetaDate } from "../components/common/Metadate/Metadate";
import { ModalCustom } from "../components/common/ModalCustom/ModalCustom";
import { useRedirect } from "../hooks/useRedirect";
import { useTranslation } from "../hooks/useTranslation";
import { useCheckPathParams } from "../hooks/useCheckPathParams";
import { useLoadSeo } from "../hooks/useLoadSeo";
import { CustomModal } from "../components/ui/CustomModal/CustomModal";
import { PaymentSuccessPage } from "../pages/PaymentSuccessPage";
import { loadZohoChat } from "../utils/helpers/loadZohoChat";
import usePaddle from "../hooks/usePaddle";

export const IndexLayout = () => {
  useRedirect();
  useCheckPathParams();
  useLoadSeo();
  usePaddle();
  // **Props
  const { lang, status } = useParams();
  const navigate = useNavigate();
  const { paymentModal } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const amount = searchParams.get("amount");
  const result = searchParams.get("result");

  // Local state
  const [isModalOpen, setIsModalOpen] = useState(false);
  // **Redux State
  // const { seo } = useSelector((state) => state.content);
  // Check status
  const defineCorrectStatus = () => {
    const paymentResults = {
      success: `${paymentModal?.replenishment?.success} ${amount}$!`,
      pending: paymentModal?.replenishment?.pending,
      cancel: paymentModal?.replenishment?.cancel,
    };
    const paymentStatuses = {
      success: paymentModal?.success,
      pending: paymentModal?.pending,
      cancel: paymentModal?.cancel,
    };

    return (
      paymentResults[result] || paymentStatuses[status] || paymentModal?.cancel
    );
  };

  // set modal open
  useEffect(() => {
    if (result) {
      setSearchParams({
        tab: "balance",
        result,
        amount: amount ? amount : "",
      });
    }
    if (status || result) {
      setIsModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, result]);
  // load script ru ?
  // useEffect(() => {
  //   const scriptId = jivoScriptIds[lang];
  //   const scriptUrl = jivoScriptUrls[lang];
  //   loadExternalScript({ scriptId, scriptUrl });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // load script ru ? replese useRemovePreloader
  // const currentDependence = () => {
  //   const dependencies = [];
  //   if (status) {
  //     dependencies.push(status);
  //   }
  //   if (result) {
  //     dependencies.push(result);
  //   }
  //   const keys = Object.keys(seo.data);
  //   // return [...dependencies, keys?.length];
  // };

  // useRemovePreloader(currentDependence());
  // load zoho chat script

  // useEffect(() => {
  //   loadZohoChat(lang);
  // }, [])

  loadZohoChat(lang);
  
  const customModalOnClose = () => {
    document.body.classList.remove("disable-scroll");
    setIsModalOpen(false);
    if (result) {
      navigate(`/${lang}/dashboard/?tab=balance`);
    } else {
      navigate(`/${lang}/`);
    }
  };
  
  return (
    <>
      <MetaDate />
      {/* {isSSR() && <Loading />} */}
      {/*<div className="wrapper" style={isSSR() ? { display: "none" } : {}}>*/}
      <div className="wrapper">
        <Header />
        <main>
          {status === "success" ? <PaymentSuccessPage /> : <Outlet />}
        </main>
        <ModalCustom />
        <Footer />
        {(status || amount) && status !== "success" && (
          <CustomModal open={isModalOpen} onClose={customModalOnClose}>
            <div className="modal__payment-status">{defineCorrectStatus()}</div>
          </CustomModal>
        )}
        
        <ToastContainer
          className={"custom-toast"}
          position="top-right"
          autoClose={8000}
          newestOnTop
          closeOnClick={false}
          hideProgressBar
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          draggable={false}
          limit={3}
        />
      </div>
    </>
  );
};
