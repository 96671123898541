import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "../../../../components/ui/Container/Container";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import "./OurClients.scss";

export const OurClients = () => {
  const { skin } = useSelector((state) => state.header);

  const crmBreakpoints = {
    320: {
      spaceBetween: 32
    },
    480: {
      spaceBetween: 48
    },
    1024: {
      spaceBetween: 64
    }
  };

  const logos = [
    { id: 1, logo: "trustedReviews", href: "https://trustedrevie.ws/reviews/proxy-ipv4.com", width: 186, height: 30, description: "Ream more reviews about proxy-ipv4 at Trusted Reviews" },
    // { id: 2, logo: "ratingProxyLogo", href: "https://rating-proxy.info/proxy/https-proxy-ipv4-com/#characteristics", width: 206, height: 42, description: "Ream more reviews about proxy-ipv4 at Rating Proxy" },
    { id: 3, logo: `trustytechLogo--${skin}`, href: "https://trustytech.io/ru/proxy/https-proxy-ipv4-com/", width: 166, height: 28, description: "Ream more reviews about proxy-ipv4 at Rating Proxy" },
    { id: 4, logo: "blackHatWorld", href: "https://www.blackhatworld.com/seo/proxy-ipv4-com-private-4g-lte-ipv4-and-ipv6-proxies-from-0-36-per-ip-20-locations.1458546/", width: 250, height: 55, description: "Ream more reviews about proxy-ipv4 at Black Hat World" },
    { id: 5, logo: "osBot", href: "https://osbot.org/forum/topic/174017-☄️%EF%B8%8Fproxy-ipv4com-private-4glte-ipv4-and-ipv6-proxies-from-036-per-ip-20-locations-✅/", width: 69, height: 61, description: "Ream more reviews about proxy-ipv4 at OSBot" },
    { id: 6, logo: "wotLogo", href: "https://www.mywot.com/scorecard/proxy-ipv4.com", width: 134, height: 59, description: "Ream more reviews about proxy-ipv4 at mywot" }
  ];

  return (
    <section id="our-clients" className="our-clients">
      <Container>
        <div className="our-clients__container">
          <div className="our-clients__container-1024">
            <Swiper
              slidesPerView="auto"
              loop
              breakpoints={crmBreakpoints}
            >
              {logos.map(({ logo, href, width, height, description }) => (
                <SwiperSlide
                  // tag="li"
                  className="our-clients__slide"
                  style={{ height: "auto", width }}
                  key={logo}
                >
                  <a
                    href={href}
                    target="_blank"
                    rel="noreferrer nofollow"
                    style={{ display: "block", maxHeight: height }}
                    aria-label={description}
                  >
                    {/*<IconSvg tag={logo} className="our-clients__icon" />*/}
                    <IconSvg
                      tag={logo}
                      className={`our-clients__logo our-clients__${logo}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="our-clients__container-1025">
            <ul className="our-clients__desktop-list">
              {logos.map(({ logo, href, height, description }) => (
                <li key={logo}>
                  <a
                    href={href}
                    target="_blank"
                    rel="noreferrer nofollow"
                    style={{ display: "block", maxHeight: height }}
                    aria-label={description}
                  >
                    <IconSvg
                      tag={logo}
                      className={`our-clients__logo our-clients__${logo}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
};
