import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "../../hooks";
import { Container } from "../../components/ui/Container/Container";
import { successToast } from "../../utils/helpers/customToast.helper";
import { IconSvg } from "../../utils/constants/icons/iconSvg.constant";
import { FeedbackForm } from "./components/FeedbackForm/FeedbackForm";
import "./ContactsPage.scss";

 const ContactsPage = () => {
  // *Props
  const { lang } = useParams();

  const { notifications, contacts: { title, description } } = useTranslation();

   // ** Redux State
   const { content } = useSelector((state) => state.content);

   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);

   const cleanHtml = DOMPurify
     .sanitize(content?.data[lang]?.metaDateFeedback?.description || description);

   const handleCopy = () => successToast(notifications?.copy, lang);

   const socials = [
     { name: "Telegram", tag: "proxyipv4com", icon: "newTelegram", link: content?.data[lang]?.telegramLink },
     { name: "E-mail", tag: "support@proxy-ipv4.com", icon: "newEmail", link: `mailto:${content?.data[lang]?.email}` }
   ];

  return (
    content?.isLoaded && (
      <section className="contacts" id="contacts">
        <Container>
          <h1 className="contacts__title">{content?.data[lang]?.metaDateFeedback?.title || title}</h1>
          <p className="contacts__description" dangerouslySetInnerHTML={{ __html: cleanHtml }} />
          <div className="contacts__wrapper">
            <FeedbackForm/>
            <div className="contacts__info">
              {socials.map((social) => (
                <div key={social.name} className="contacts__social-item">
                  <div className="contacts__social-name">
                    <IconSvg tag={social.icon} />
                    <div>{social.name}</div>
                  </div>
                  <div className="contacts__social-link">
                    <a href={social.link}>{social.tag}</a>
                    <CopyToClipboard text={social.link} onCopy={handleCopy}>
                      <span>
                        <IconSvg tag="newCopy" className="contacts__socials-copy"/>
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              ))}
              {/*<Socials/>*/}
            </div>
          </div>
        </Container>
      </section>
    )
  );
 };

export default ContactsPage;
