import {useIsomorphicLayoutEffect} from "./useIsomorphicLayoutEffect";

export const useSetZIndex = (selector, index) => {
  return useIsomorphicLayoutEffect(() => {
    const element = document.querySelector(selector);

    if (!element) return;

    const prevZIndexValue = window.getComputedStyle(element).zIndex || "0";

    element.style.zIndex = index;
  
    // eslint-disable-next-line no-return-assign
    return () => element.style.zIndex = prevZIndexValue;
  }, []);
};
