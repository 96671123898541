import { useState } from "react";
import { PriceItemTemplate } from "../PriceItemTemplate/PriceItemTemplate";
import { EnterpriseModal } from "../EnterpriseModal/EnterpriseModal";
import { useTranslation } from "../../../../../../hooks";
import "./EnterpriseItem.scss";

export const EnterpriseItem = () => {
  const [isEnterpriseModalOpen, setIsEnterpriseModalOpen] = useState(false);

  const {
    prices: { priceItem: {
      enterpriseDescription,
      enterpriseModal: { buttonText }
    } }
  } = useTranslation();

  const handleOpenModal = () => {
    setIsEnterpriseModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEnterpriseModalOpen(false);
  };

  return (
    <>
      <PriceItemTemplate handler={handleOpenModal} buttonText={buttonText}>
        <div className="enterprise">
          <div className="enterprise__title">
            Enterprise
          </div>
          <p className="enterprise__description">
            {enterpriseDescription}
          </p>
        </div>
      </PriceItemTemplate>
      { isEnterpriseModalOpen && <EnterpriseModal isOpen={isEnterpriseModalOpen} onClose={handleCloseModal} /> }
    </>
  );
};
