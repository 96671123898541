import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Container } from "../../../../../components/ui/Container/Container";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";

import { useDispatchedActions } from "../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../hooks/useTranslation";

import { Actions } from "../Actions/Actions";
import { ScrollNav } from "../ScrollNav/ScrollNav";
import { SearchCountries } from "../SearchCountries/SearchCountries";
import "./MobileMenu.scss";
import { Button } from "../../../../../components/ui/Buttons";
import { PROXY_TYPES } from "../../../../../utils/constants";

export const MobileMenu = ({ loginFormHandler }) => {
  // **Props
  const { lang } = useParams();

  // ** Dispatch
  const {
    setMenu,
    setDropdownMenu,
    setMobileCountriesDropdownMenu,
    setActiveProxy,
    setActiveCountry
  } = useDispatchedActions();

  // ** Redux State
  const {
    isMenuOpened,
    isMobileDropdownOpened,
    mobileCountriesDropdownOpened
  } = useSelector((state) => state.header);
  const { proxies } = useSelector((state) => state.content);

  // **Hooks
  const {
    header: { menu, menu: { back } }
  } = useTranslation();

  // ** Refs
  const mobileNavRef = useRef(null);
  const mobileDropdownRef = useRef(null);

  // ** Local State
  const [maxHeightMenu, setMaxHeightMenu] = useState("0px");
  const [maxHeightProxy, setMaxHeightProxy] = useState(0);
  const [shouwMenu, setShouwMenu] = useState("PROXY");
  const [localProxy, setLocalProxy] = useState([]);
  const [searchData, setSearchData] = useState("");
  // Dropdown handler
  const proxiesDropdown = () => {
    setDropdownMenu(!isMobileDropdownOpened);
  };

  const setDefaultParamsMenu = () => {
    setDropdownMenu(false);
    setMenu(false);
    setMobileCountriesDropdownMenu("");
    setShouwMenu("PROXY");
  };

  // handler click
  // const handlerClick = (data, item) => {
  //   setActiveProxy(data);
  //   if (data?.code === "IPv4" || data?.code === "IPv6") {
  //     setLocalProxy(data);
  //   }
  //
  //   if (!item) {
  //     setShouwMenu("COUNTRY");
  //
  //     return;
  //   }
  //
  //   const tempDataCountry = {
  //     id: item?.id || "",
  //     code: item?.code || ""
  //   };
  //
  //   setActiveCountry(tempDataCountry);
  //   setDefaultParamsMenu();
  // };

  const handlerClick = (e, data, item) => {
    setActiveProxy(data);

    if (data?.code === PROXY_TYPES.mobile.code) {
      setDefaultParamsMenu();

      return;
    }

    setLocalProxy(data);

    if (!item) {
      setShouwMenu("COUNTRY");

      return;
    }

    const tempDataCountry = {
      id: item?.id || "",
      code: item?.code || ""
    };

    setActiveCountry(tempDataCountry);
    setDefaultParamsMenu();
  };

  useEffect(() => {
    if (!isMenuOpened) {
      setDefaultParamsMenu();
    }
    const { body } = document;
    if (isMenuOpened) {
      body.classList.add("disable-scroll");
    } else {
      body.classList.remove("disable-scroll");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpened]);

  useEffect(() => () => {
    setDefaultParamsMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isMenuOpened) {
      setMaxHeightMenu("0px");
      setMaxHeightProxy("0px");
      return;
    }
    if (shouwMenu === "PROXY") {
      if (!isMobileDropdownOpened) {
        setMaxHeightProxy("0px");
        setMaxHeightMenu(`${mobileNavRef?.current?.scrollHeight}px`);
        return;
      }

      // if (mobileCountriesDropdownOpened === "") {
      //   setMaxHeightMenu(
      //     `${
      //       mobileNavRef?.current?.scrollHeight +
      //       mobileDropdownRef?.current?.scrollHeight
      //     }px`
      //   );
      //   setMaxHeightProxy(`${mobileIPv4Ref?.current?.scrollHeight}px`);
      //   return null;
      // }
      setMaxHeightMenu(
        `${
          mobileNavRef?.current?.scrollHeight +
          mobileDropdownRef?.current?.scrollHeight
        }px`
      );
      setMaxHeightProxy(`${mobileDropdownRef?.current?.scrollHeight}px`);
    } else {
      setMaxHeightProxy("0px");
      setMaxHeightMenu("100%");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMenuOpened,
    isMobileDropdownOpened,
    mobileCountriesDropdownOpened,
    shouwMenu,
    searchData
  ]);

  return (
    <div
      ref={mobileNavRef}
      className="header__mobile"
      style={{ maxHeight: maxHeightMenu }}
    >
      {shouwMenu === "PROXY" ? (
        <nav className="header-nav">
          <div className="header-nav-proxy">
            <Button
              tab="dark-gradient"
              onClick={proxiesDropdown}
              active={isMobileDropdownOpened}
              initClass={isMobileDropdownOpened ? "btn__header--active header__desc--open" : ""}
            >
              {menu?.proxyNav?.name}
              <IconSvg tag="headerDropDown" />
            </Button>
            <div
              className={`header__dropdown ${
                isMobileDropdownOpened ? "header__dropdown-active" : ""
              }`}
              ref={mobileDropdownRef}
              style={{
                maxHeight: maxHeightProxy
              }}
            >
              <ul className="header__dropdown-list">
                {proxies?.enabled.map((data) => (
                  <li className="header__dropdown-list-item" key={data?.value}>
                    <Link
                      className="header__dropdown-list-proxy"
                      to={`/${lang}/${
                        data?.code !== "IPv4" ? `${data?.value}/` : ""
                      }`}
                      // onClick={() => setDefaultParamsMenu()}
                      onClick={(e) => handlerClick(e, data)}
                    >
                      {data?.code !== "MOBILE" ? (
                        `${menu?.proxyNav?.subName} ${data?.label}`
                      ) : (
                        menu?.proxyNav?.subNameMobile
                      )}
                      {data?.code !== "MOBILE" && (
                        <IconSvg tag="arrowRight" className="header__dropdown-arrow" />
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Container>
            <ScrollNav />
          </Container>
        </nav>
      ) : (
        <div className="header__countries">
          <button
            className="header__go-back"
            onClick={() => setShouwMenu("PROXY")}
          >
            <IconSvg tag="classicArrowLeft" className="header__go-back-arrow" />{" "}
            {back}
          </button>
          <SearchCountries
            proxy={localProxy}
            searchTrigger={setSearchData}
            handlerClick={handlerClick}
          />
        </div>
      )}
      <Container>
        <Actions loginFormHandler={loginFormHandler} isMobileMenu={true} />
      </Container>
    </div>
  );
};
